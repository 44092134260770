import React, { useContext } from 'react'

import { Context } from '../WrapperProvider'

import './price_card.scss'

const PriceCard = (props) => {
  const context = useContext(Context)

  const { title, price, btnText, subText, className } = props

  const priceSpan = context.locale === 'it-IT' ? '€' : '£'
  const frequency = context.locale === 'it-IT' ? 'mese' : 'month'

  return (
    <div className={`${className} sm-card`}>
      <h2 className={'sm-card--title'}>{title}</h2>

      <div className={'sm-card--body'}>
        {price && (
          <p>
            <span className={'text--big'}>
              {priceSpan}
              {price}
            </span>{' '}
            /{frequency}
          </p>
        )}
        {subText && <p className={'sm-card--sub'}>{subText}</p>}
      </div>
      {btnText && (
        <a
          className={'sm-card--link'}
          href="https://dashboard.clinicsoul.com/sign-up"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={'btn btn-transparent btn-full'}>{btnText} </button>
        </a>
      )}
    </div>
  )
}

export default PriceCard
