import React from 'react'
import { FormattedMessage } from 'react-intl'

import './price_column.scss'

const PriceColumn = (props) => {
  const { type, nrOfItems } = props

  let menuItems = []
  for (var i = 0; i < nrOfItems; i++) {
    menuItems.push(
      <li className="col__list-item">
        <FormattedMessage
          id={`pricing_cols.${type}.item_${i + 1}`}
          defaultMessage="Only purchasable together with a ClinicSoul package"
        />
      </li>,
    )
  }

  return (
    <>
      <p className={'col__head'}>
        {' '}
        <FormattedMessage
          id={`pricing_cols.header`}
          defaultMessage="Features"
        />
        :
      </p>
      <ul className={'col__ul'}>{menuItems}</ul>
    </>
  )
}

export default PriceColumn
