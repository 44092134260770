import React from 'react'
import ReactPixel from 'react-facebook-pixel'

ReactPixel.pageView() // For tracking page view

const TermsAndConditions = () => {
  return (
    <div className={'terms-and-conditions-page'}>
      <div className="container-document">
        <h2 className="center">Termini di contratto e condizioni d'uso</h2>

        <p>
          I Clienti che utilizzano i Servizi offerti da ClinicSoul.com
          dichiarano di conoscere e accettare le presenti condizioni generali di
          contratto.
        </p>

        <h2 className="center">Premesse</h2>
        <p>
          Il presente documento costituisce un accordo legale fra il Cliente e
          la società che gestisce ClinicSoul.com e disciplina l'utilizzo del
          sito web e/o dell'applicazione ed, in ogni caso, l'uso dei Servizi
          forniti. "Accordo legale" significa che i termini di tale accordo, una
          volta accettati dalIl Cliente, sono vincolanti per quest'ultimo.
        </p>

        <h2 className="center">Definizioni</h2>
        <p>
          <strong>"Cliente"</strong> sia al singolare che al plurale, si
          riferisce a strutture sanitarie che utilizzano i Servizi offerti da
          ClinicSoul.com;
        </p>
        <p>
          <strong>"ClinicSoul.com"</strong> si riferisce al presente sito e/o
          qualunque applicazione di proprietà del Titolare;
        </p>
        <p>
          <strong>"Conferma Pagamento"</strong> indica la email inviata da
          ClinicSoul.com per confermare l'avvenuto acquisto dell'abbonamento e
          dei Servizi scelti;
        </p>
        <p>
          <strong>"Contratto"</strong> si riferisce al presente documento, come
          di volta in volta emendato. Il Contratto è concluso in lingua
          italiana.
        </p>
        <p>
          <strong>"Piano Abbonamento"</strong> indicano i diversi piani di
          abbonamento disponibili per i Clienti di ClinicSoul.com;
        </p>
        <p>
          <strong>"Privacy Policy"</strong> indica la policy privacy utilizzata
          da ClinicSoul.com
        </p>
        <p>
          <strong>"Servizi"</strong> indicano le caratteristiche e le
          funzionalità che i Clienti possono utilizzare tramite il sito
          ClinicSoul.com;
        </p>
        <p>
          <strong>"Titolare"</strong> si riferisce alla società che possiede e
          gestisce ClinicSoul.com;
        </p>

        <h2 className="center">1. Accettazione del Contratto</h2>
        <p>
          Per poter utilizzare ClinicSoul.com, i Clienti devono leggere
          attentamente ed accettare il Contratto cliccando sullo specifico
          pulsante per l'accettazione. Se il Cliente non accetta il Contratto
          non potrà utilizzare il Servizio.
        </p>

        <h2 className="center">2. Descrizione del Servizio</h2>
        <p>
          ClinicSoul.com è una piattaforma applicativa integrata per la gestione
          dei processi clinici, amministrativi e organizzativi di strutture
          sanitarie.
        </p>
        <p>
          <strong>Tramite ClinicSoul.com il Cliente potrà:</strong>
        </p>
        <ul>
          <li>
            <p>
              Creare e gestire le cartelle cliniche dei suoi pazienti
              personalizzando i campi da inserire e archiviare in maniera sicura
              tutti i documenti;
            </p>
          </li>
          <li>
            <p>
              Organizzare e gestire in maniera intelligente gli appuntamenti
              attraverso un unico calendario;
            </p>
          </li>
          <li>
            <p>
              Comunicare con i pazienti, attuali e potenziali, attraverso
              strumenti di marketing automation dedicati;
            </p>
          </li>
          <li>
            <p>
              Inviare le fatture elettroniche e gestire costi e ricavi da un
              unica dasboard;
            </p>
          </li>
          <li>
            <p>Organizzare video conferenze con i propri pazienti;</p>
          </li>
        </ul>

        <h2 className="center">3. Registrazione</h2>
        <p>
          Per poter utilizzare il Servizio o parte di esso, il Cliente deve
          registrarsi fornendo, in maniera veritiera e completa, tutti i dati
          richiesti nel relativo form di registrazione ed accettare
          integralmente la Privacy Policy di ClinicSoul.com e il presente
          Contratto. Il Cliente ha l'onere di custodire e mantenere riservate le
          proprie credenziali di accesso. Resta inteso che in nessun caso il
          Titolare potrà essere ritenuto responsabile in caso di smarrimento,
          diffusione, furto o utilizzo non consentito da parte di terzi, a
          qualsiasi titolo, delle credenziali di accesso dei Clienti.
        </p>

        <h2 className="center">
          4. Cancellazione account e chiusura degli account Cliente
        </h2>
        <p>
          I Clienti registrati possono disattivare i propri account, richiederne
          la cancellazione oppure interrompere l'utilizzo del Servizio in ogni
          momento, contattando direttamente il Titolare, fatto salvo quanto
          dovuto in base all'abbonamento sottoscritto.
        </p>
        <p>
          Il Titolare, in caso di violazione del presente Contratto, si riserva
          il diritto di sospendere o chiudere l'account del Cliente in ogni
          momento e senza preavviso.
        </p>
        <p>
          <strong>
            Il Titolare si riserva il diritto di sospendere o cancellare
            l'account del Cliente in qualsiasi momento e senza preavviso se
            ritiene che:
          </strong>
        </p>

        <ul>
          <li>
            <p>Il Cliente abbia violato il Contratto; e/o</p>
          </li>
          <li>
            <p>
              l'accesso o l'uso del Servizio potrebbero causare danni al
              Titolare, ad altri Clienti o a terze parti; e/o
            </p>
          </li>
          <li>
            <p>
              l'uso di ClinicSoul.com da parte del Cliente possa risultare in
              violazione della legge o dei regolamenti applicabili; e/o
            </p>
          </li>
          <li>
            <p>
              in caso d'investigazioni condotte a seguito di azioni legali o per
              coinvolgimento della pubblica autorità; e/o
            </p>
          </li>
          <li>
            <p>
              l'account è ritenuto dal Titolare, a sua esclusiva discrezionalità
              e per qualsivoglia motivo, inappropriato o offensivo o in
              violazione del Contratto o non in linea con gli standard di
              Servizio del Titolare.
            </p>
          </li>
        </ul>

        <p>
          In nessun caso la chiusura o la sospensione dell'account del Cliente
          da parte del Titolare possono costituire titolo per qualsivoglia
          richiesta di indennizzo o pretesa risarcitoria da parte del Cliente.
        </p>

        <h2 className="center">5. Contenuti presenti su ClinicSoul.com</h2>
        <p>
          I contenuti disponibili su ClinicSoul.com sono protetti dalla legge
          sul diritto d'autore e dalle altre leggi e trattati internazionali
          posti a tutela dei diritti di proprietà intellettuale e, ove non
          diversamente specificato, il loro utilizzo è consentito ai Clienti
          esclusivamente nei limiti specificati nella presente clausola. Il
          Titolare concede al Cliente, per tutta la durata del Contratto, una
          licenza personale, non trasferibile e non esclusiva, ad esclusivi fini
          personali e mai commerciali e limitatamente al dispositivo in uso al
          Cliente per l'utilizzo di tali contenuti. Pertanto, è fatto espresso
          divieto al Cliente di copiare e/o scaricare e/o condividere (se non
          nei limiti di seguito illustrati), modificare, pubblicare,
          trasmettere, vendere, sublicenziare, elaborare, trasferire/cedere a
          terzi o creare lavori derivati in qualsiasi modo dai contenuti, anche
          di terzi, disponibili su ClinicSoul.com, nè consentire che terzi lo
          facciano tramite Il Cliente o il suo dispositivo, anche a sua
          insaputa.
        </p>
        <p>
          Ove espressamente indicato su ClinicSoul.com il Cliente, per mero uso
          personale, può essere autorizzato a scaricare e/o copiare e/o
          condividere alcuni contenuti resi disponibili su ClinicSoul.com a
          condizione che riporti fedelmente tutte le indicazioni di copyright e
          le altre indicazioni fornite dal Titolare.
        </p>

        <h2 className="center">6. Contenuti forniti dal Cliente</h2>
        <p>
          I Clienti sono responsabili per i contenuti propri e di terzi che
          condividono su ClinicSoul.com, mediante il loro caricamento,
          l'inserimento di contenuti o con qualsiasi altra modalità. I Clienti
          manlevano il Titolare da ogni responsabilità in relazione alla
          diffusione illecita di contenuti di terzi o all'utilizzo di
          ClinicSoul.com, con modalità contrarie alla legge.
        </p>
        <p>
          Il Titolare non svolge alcun tipo di controllo sulla veridicità dei
          contenuti pubblicati dal Cliente (ad esempio le informazioni contenute
          nelle cartelle cliniche dei pazienti) ma si impegna ad intervenire a
          fronte di segnalazioni di terzi o di ordini impartiti da pubbliche
          autorità in relazione a contenuti ritenuti offensivi o illeciti.
        </p>
        <p>
          In particolare il Titolare non ha alcuna responsabilità circa il
          legittimo trattamento dei dati sanitari personali dei pazienti
          inseriti dal Cliente su ClinicSoul.com.
        </p>
        <p>
          E' infatti onere del Cliente acquisire in via preliminare tutti i
          consensi necessari al trattamento dei dati personali dei propri
          pazienti.
        </p>

        <h2 className="center">7. Utilizzo non consentito</h2>
        <p>
          Il Servizio dovrà essere utilizzato secondo quanto stabilito nel
          presente Contratto.
        </p>
        <p>
          <strong>I Clienti non possono:</strong>
        </p>
        <ul>
          <li>
            <p>
              effettuare reverse engineering, decompilare, disassemblare,
              modificare o creare lavori derivati basati su ClinicSoul.com o su
              qualunque porzione di essa;
            </p>
          </li>
          <li>
            <p>
              aggirare i sistemi informatici usati da ClinicSoul.com o dai suoi
              licenziatari per proteggere il contenuto accessibile tramite di
              essa;
            </p>
          </li>
          <li>
            <p>
              copiare, conservare, modificare, cambiare, preparare lavori
              derivati o alterare in qualunque modo qualunque dei contenuti
              forniti da ClinicSoul.com;
            </p>
          </li>
          <li>
            <p>
              utilizzare qualunque robot, spider, applicazione di ricerca e/o di
              reperimento di siti, ovvero qualunque altro dispositivo, processo
              o mezzo automatico per accedere, recuperare, effettuare scraping o
              indicizzare qualunque porzione di ClinicSoul.com o dei suoi
              contenuti;
            </p>
          </li>
          <li>
            <p>affittare, licenziare o sublicenziare ClinicSoul.com;</p>
          </li>
          <li>
            <p>
              utilizzare ClinicSoul.com in qualunque altra modalità impropria
              tale da violare questo Contratto;
            </p>
          </li>
          <li>
            <p>
              diffamare, offendere, molestare, mettere in atto pratiche
              minatorie, minacciare o in altro modo violare i diritti di altri;
            </p>
          </li>
          <li>
            <p>
              diffondere o pubblicare contenuti illegali, osceni, illegittimi,
              diffamatori o inappropriati;
            </p>
          </li>
          <li>
            <p>
              appropriarsi illecitamente dell'account in uso presso altro
              Utente;
            </p>
          </li>
          <li>
            <p>
              utilizzare ClinicSoul.com in qualunque altra modalità impropria
              tale da violare il Contratto;
            </p>
          </li>
        </ul>

        <h2 className="center">8.Diritti di utilizzo per il Cliente</h2>
        <p>
          Al momento dell’acquisto di un abbonamento al Cliente viene concesso
          per tutta la durata del contratto il diritto semplice e non esclusivo
          di utilizzare i Servizi offerti da ClincSoul.com. Il Cliente non ha
          diritto di utilizzare il software oltre la durata di utilizzo
          prescritta né di farlo utilizzare a terzi né di renderlo accessibile a
          terzi. Inoltre il Cliente non è autorizzato a riprodurlo o venderlo.
          Il diritto di utilizzo non è cedibile.
        </p>

        <h2 className="center">9.Risoluzione del contratto con il Titolare</h2>
        <p>
          Il Cliente può risolvere il proprio contratto con il Titolare in ogni
          momento. Alla risoluzione, i Clienti restano comunque obbligati
          contrattualmente a corrispondere ad ClinicSoul.com tutte le somme
          ancora dovute oltre ad interessi e spese, se applicabili.
        </p>
        <p>
          In nessun caso l'esercizio del diritto di terminare il contratto da
          parte del Cliente costituirà titolo per richiedere risarcimenti di
          qualunque genere, tipo e per qualsiasi motivo.
        </p>

        <h2 className="center">10. Condizioni di pagamento</h2>
        <p>
          Il Cliente deve selezionare il Piano Abbonamento prescelto e
          completare l'acquisto mediante l'inserimento dei dati di pagamento,
          dopo aver verificato attentamente le informazioni inserite. L'ordine è
          effettuato mediante la conferma dello stesso ed è subordinato al
          pagamento del prezzo e delle imposte. La conclusione del Contratto
          avviene al momento dell'invio della Conferma di Pagamento da parte del
          Titolare all'indirizzo email fornito dal Cliente. Il corrispettivo
          pagato per l'acquisto del Piano Abbonamento non è in nessun caso
          rimborsabile.
        </p>
        <p>Il Piano Abbonamento ha validità mensile o annuale.</p>
        <p>
          I prezzi, le descrizioni o la disponibilità dei Piani Abbonamenti
          esposti sono soggetti a modifica senza preavviso.
        </p>

        <h2 className="center">11. Modalità di pagamento</h2>
        <p>
          I metodi di pagamento accettati sono esclusivamente quelli elencati
          nella procedura acquisto del Piano Abbonamenti presenti sul sito web
          ClinicSoul.com.
        </p>
        <p>
          ClinicSoul.com utilizza strumenti terzi per l'elaborazione dei
          pagamenti e non entra in alcun modo in contatto con dati di pagamento
          – come quelli relativi alla carta di credito - forniti. Qualora il
          titolare di ciascuno di tali strumenti terzi dovesse rifiutare
          l'autorizzazione al pagamento, ClinicSoul.com. non potrà attivare il
          Piano Abbonamenti prescelto.
        </p>

        <h2 className="center">12. Sconti e offerte</h2>
        <p>
          Il Titolare si riserva, a sua esclusiva ed incontestabile
          discrezionalità, di offrire sconti e/o offerte per un periodo limitato
          di tempo. Le condizioni cui soggiacciono tali sconti e/o offerte sono
          di volta in volta indicate nella relativa pagina informativa su
          ClinicSoul.com. Ciascuno sconto e/o offerta saranno validi fino alla
          scadenza prefissata.
        </p>

        <h2 className="center">13. Manleva</h2>
        <p>
          Il Cliente s'impegna a mantenere indenne il Titolare (nonchè le
          eventuali società dallo stesso controllate o affiliate, i suoi
          rappresentanti, amministratori, agenti, licenziatari, partner e
          dipendenti), da qualsiasi obbligo o responsabilità, incluse le
          eventuali spese legali sostenute per difendersi in giudizio, che
          dovessero sorgere a fronte di danni provocati ad altri Clienti o a
          terzi, in relazione ai contenuti caricati online, alla violazione
          della legge o dei termini delle presenti condizioni di servizio.
        </p>

        <h2 className="center">14. Limitazioni di responsabilità</h2>
        <p>
          ClinicSoul.com e tutte le funzionalità accessibili attraverso
          ClinicSoul.com vengono messi a disposizione degli Clienti, ai termini
          e alle condizioni di cui al Contratto, senza alcuna garanzia,
          esplicita o implicita, che non sia obbligatoria per legge. In
          particolare, non viene fornita alcuna garanzia d'idoneità dei Servizi
          offerti per gli scopi particolari che si prefigge Il Cliente.
        </p>
        <p>
          L'uso di ClinicSoul.com e delle funzionalità accessibili attraverso
          ClinicSoul.com è effettuato dagli Clienti a proprio rischio e sotto la
          propria responsabilità. In particolare il Titolare, nei limiti del
          diritto applicabile, risponde per i danni di natura contrattuale ed
          extracontrattuale nei confronti di Clienti e terzi esclusivamente a
          titolo di dolo o colpa grave quando questi costituiscono conseguenza
          immediata e diretta dell'attività di ClinicSoul.com.
        </p>
        <p>
          <strong>Pertanto, il Titolare non sarà responsabile per:</strong>
        </p>
        <ul>
          <li>
            <p>
              eventuali perdite che non siano conseguenza diretta della
              violazione del Contratto da parte del Titolare;
            </p>
          </li>
          <li>
            <p>
              ogni perdita di opportunità commerciale e qualsiasi altra perdita,
              anche indiretta, eventualmente subita dal Cliente (quali, a titolo
              esemplificativo e non esaustivo, perdite commerciali, perdita di
              ricavi, introiti, profitti o risparmi presunti, perdita di
              contratti o di relazioni commerciali, perdita della reputazione o
              del valore di avviamento etc.);
            </p>
          </li>
          <li>
            <p>
              danni o perdite derivanti da interruzioni o malfunzionamenti di
              ClinicSoul.com dovuti ad eventi di forza maggiore o, comunque, ad
              eventi imprevisti ed imprevedibili e, comunque, indipendenti dalla
              volontà ed estranei alla sfera di controllo del Titolare quali, a
              titolo esemplificativo e non esaustivo, guasti o interruzioni alle
              linee telefoniche o elettriche, alla rete internet e/o comunque ad
              altri strumenti di trasmissione, indisponibilità di siti web,
              scioperi, eventi naturali, virus ed attacchi informatici,
              interruzioni nell'erogazione di prodotti, servizi o applicazioni
              di terze parti ed errato o inidoneo utilizzo di ClinicSoul.com da
              parte degli Clienti o di terzi;
            </p>
          </li>
        </ul>

        <h2 className="center">15. Interruzione del Servizio</h2>
        <p>
          Il Titolare si riserva il diritto di aggiungere, rimuovere
          funzionalità o caratteristiche ovvero sospendere o interrompere del
          tutto la fornitura del Servizio, sia in via temporanea che definitiva.
          In caso d'interruzione definitiva, il Titolare agirà come possibile
          per permettere ai Clienti di prelevare le proprie informazioni
          ospitate presso il Titolare.
        </p>
        <h2 className="center">16. Privacy Policy</h2>
        <p>
          Per informazioni sull'utilizzo dei dati personali, gli Clienti devono
          fare riferimento alla privacy policy di ClinicSoul.com, che si trova
          al seguente link: Privacy Policy{' '}
        </p>
        <h2 className="center">17. Diritti di proprietà intellettuale</h2>
        <p>
          Tutti i marchi e tutti gli altri segni, nomi commerciali, marchi di
          servizio, marchi denominativi, denominazioni commerciali,
          illustrazioni, immagini, loghi riguardanti terze parti ed i contenuti
          pubblicati da tali terzi su ClinicSoul.com sono e rimangono di
          proprietà esclusiva o nella disponibilità di detti terzi e dei loro
          licenziatari e sono protetti dalle leggi vigenti sui marchi e dai
          relativi trattati internazionali. Il Titolare non possiede la
          titolarità di tali privative intellettuali e può servirsene solo entro
          i limiti ed in conformità ai contratti conclusi con tali terze parti e
          per gli scopi ivi delineate.
        </p>
        <h2 className="center">18. Requisiti di età</h2>
        <p>
          I Clienti dichiarano di essere maggiorenni secondo la legislazione
          italiana. I minorenni non possono utilizzare ClinicSoul.com.
        </p>
        <h2 className="center">19. Modifiche ai presenti Termini</h2>
        <p>
          Il Titolare si riserva il diritto di apportare modifiche al Contratto
          in qualunque momento, dandone avviso ai Clienti mediante la sua
          pubblicazione all'interno di ClinicSoul.com. Il Cliente che continui
          ad utilizzare ClinicSoul.com. successivamente alla pubblicazione delle
          modifiche, accetta senza riserva il nuovo Contratto.
        </p>
        <h2 className="center">20. Cessione del contratto</h2>
        <p>
          Il Titolare si riserva il diritto di trasferire, cedere, disporre per
          novazione o subappaltare tutti o alcuni dei diritti o obblighi
          derivanti dal Contratto, purchè i diritti del Cliente qui previsti non
          siano pregiudicati. Il Cliente non potrà cedere o trasferire in alcun
          modo i propri diritti o obblighi ai sensi del presente Contratto senza
          l'autorizzazione scritta del Titolare.
        </p>
        <h2 className="center">21. Comunicazioni</h2>
        <p>
          Tutte le comunicazioni relative ad ClinicSoul.com devono essere
          inviate utilizzando le informazioni di contatto indicate nel
          Contratto.
        </p>
        <h2 className="center">22. Inefficacia e nullità parziale</h2>
        <p>
          Qualora una qualsiasi clausola del presente Contratto dovesse
          risultare nulla, non valida o inefficace, la suddetta clausola sarà
          eliminata mentre le restanti clausole non saranno da ciò condizionate
          e rimarranno pienamente efficaci.
        </p>
        <h2 className="center">23. Legge e Foro applicabile</h2>
        <p>
          Il presente Contratto e tutte le controversie in merito ad esecuzione,
          interpretazione e validità sono soggette alla legge Italiana. Il foro
          applicabile sarà quello di Catania. Fa eccezione il foro esclusivo del
          consumatore, qualora la legge lo preveda.
        </p>
        <h2 className="center">24. Risoluzione delle controversie</h2>
        <p>
          Il consumatore residente in Europa deve essere a conoscenza del fatto
          che la Commissione Europea ha istituito una piattaforma online che
          fornisce uno strumento di risoluzione alternativa delle controversie.
          Tale strumento può essere utilizzato dal consumatore europeo per
          risolvere in via non giudiziale ogni controversia relativa a e/o
          derivante da contratti di vendita di beni e servizi stipulati in rete.
          Di conseguenza, se sei un consumatore europeo, puoi usare tale
          piattaforma per la risoluzione di ogni disputa nascente dal contratto
          online stipulato con il Titolare. La piattaforma è disponibile al
          seguente link:
        </p>
        <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=IT">
          https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=IT
        </a>
        <p>
          Il Titolare è disponibile a rispondere ad ogni quesito inoltrato via
          email al seguente indirizzo email:{' '}
          <strong>info@clinicsoul.com</strong>
        </p>

        <h2 className="center">
          25. Legge applicabile e informazioni generali obbligatorie ex D. Lgs.
          n. 70/2003.
        </h2>
        <p>
          Le presenti Condizioni Generali sono regolate dalla legge italiana.
        </p>
        <p>
          Ai sensi dell'art. 7 del Decreto Legislativo 9 aprile 2003 n. 70 si
          forniscono le seguenti informazioni generali:
        </p>
        <p>CLINICSOUL LTD</p>
        <p>
          sede legale Kemp House, 152 - 160 City Road, London, EC1V 2NX, UNITED
          KINGDOM
        </p>
        <p>Registered in UNITED KINGDOM, Company Number 13152263</p>
        <p>info@clinicsoul.com</p>
        <br />
        <p>Versione aggiornata 25 febbraio 2021</p>
      </div>
    </div>
  )
}

export default TermsAndConditions
