import React, { useState, useEffect } from 'react'
import { Route, useLocation, BrowserRouter, Switch } from 'react-router-dom'
import ReactGA from 'react-ga' //Google Analytics
import MessengerCustomerChat from 'react-messenger-customer-chat'
import ReactPixel from 'react-facebook-pixel'

import ContactContextProvider from './context/ContactContext'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
// import ChatBot from './components/ChatBot'
import Home from './pages/Landing/Home'
import Contact from './pages/Landing/Contact'
import Pricing from './pages/Landing/Pricing'
import TermsAndConditions from './pages/Landing/TermsAndConditions'
import Privacy from './pages/Landing/Privacy'
import FAQ from './pages/Landing/FAQ'

import { CLINICSOUL_FB_APP_ID, CLINICSOUL_FB_PAGE_ID } from './static/APP_INFO'

import './App.scss'

let REACT_PIXEL = ''

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

if (process.env.REACT_APP_ENV === 'production') {
  // production code
  console.log('PRODDD')
  REACT_PIXEL = '857214698192364'
  ReactPixel.init(REACT_PIXEL, null, options)
} else {
  console.log('non prod!')
}

function App() {
  const [isMobile, setIsMobile] = useState(false)
  const [pathname, setPathname] = useState('/')

  useEffect(() => {
    ReactGA.initialize('G-XXS5QMDMJN')
    ReactGA.pageview(window.location.pathname)
  })

  const location = useLocation()

  useEffect(() => {
    // Check if router changes - If user navigates to other route close mobileMenu
    if (isMobile && pathname !== location.pathname) {
      setPathname(location.pathname)
      setIsMobile(false)
    }
  }, [location, isMobile, pathname])

  return (
    <ContactContextProvider>
      <BrowserRouter>
        <Switch>
          <div className={`wrapper ${isMobile ? 'is-mobile-menu' : ''}`}>
            <Navbar
              isMobile={isMobile}
              onToggleMenu={() => setIsMobile(!isMobile)}
            />
            <Route exact path="/" component={Home} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/contact" component={Contact} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/faq" component={FAQ} />
            <Footer />
            <MessengerCustomerChat
              pageId={CLINICSOUL_FB_PAGE_ID}
              appId={CLINICSOUL_FB_APP_ID}
            />
            {/* <ChatBot /> */}
          </div>
        </Switch>
      </BrowserRouter>
    </ContactContextProvider>
  )
}

export default App
