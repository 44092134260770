import React from 'react'
import { FormattedMessage } from 'react-intl'

import './banner.scss'
import Button from '../../shared/Button'

const Banner = () => {
  return (
    <div className={'outer-lg  outer-white'}>
      <div
        className={
          'banner wrapper-rounded flex-container flex-mobile--col flex-align'
        }
      >
        <div className={'flex-item left-col'}>
          <h1 className={'main-title'}>
            <FormattedMessage
              id="banner.header"
              defaultMessage="The Clinic and Medical software you’ve always needed, only one click away"
            />
          </h1>
          <p className={'subtitle'}>
            <FormattedMessage
              id="banner.subheader"
              defaultMessage="  ClinicSoul is the scalable, secure and cross-device software to manage your clinic effectively,
                            anytime, anywhere."
            />
          </p>
          <a
            href="https://dashboard.clinicsoul.com/sign-up"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="purple" size={'lg'}>
              <FormattedMessage
                id="banner.banner_btn"
                defaultMessage=" Get your free trial"
              />
            </Button>
          </a>
          <p className={'text-info'}>
            <FormattedMessage
              id="banner.banner_sub"
              defaultMessage=" Start for free, upgrade anytime."
            />
          </p>
        </div>
        <img
          className={'banner-img tt'}
          src="banner_img.png"
          srcSet={
            'screen_tablet@3x.png 1740w, screen_tablet@2x.png 1160w, screen_tablet.png 580w'
          }
          sizes={'(min-width:50em) 36vw, 100vw'}
          alt="ipad screen banner"
        />
      </div>
    </div>
  )
}

export default Banner
