import React, { createContext, useState } from 'react'

export const ContactContext = createContext()

const ContactContextProvider = (props) => {
    const [message, setMessage] = useState('Salve, vorrei ricevere maggiori informazioni su Clinicsoul')

    return <ContactContext.Provider value={{ message, setMessage }}>{props.children}</ContactContext.Provider>
}

export default ContactContextProvider
