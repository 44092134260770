import React from 'react'
import ReactPixel from 'react-facebook-pixel'

import Banner from '../../components/Banner'
import ContainerInfo from '../../components/ContainerInfo'
import TwoCols from '../../components/TwoCols'
import TwoColsWithImg from '../../components/TwoColsWithImg'
import SectionWithAction from '../../components/SectionWithAction'
import {
  first,
  second,
  third,
  fourth,
  fifth,
} from '../../static/two_cols_content'

ReactPixel.pageView() // For tracking page view

const Home = () => {
  return (
    <div className={'home-page'}>
      <Banner />
      <ContainerInfo />
      <TwoCols className={'two-cols--list-only '} data={first} />
      <TwoCols data={second} />
      <TwoCols data={third} />
      <TwoCols data={fourth} />
      <TwoCols data={fifth} />
      <TwoColsWithImg />
      {/* <ScrollGallery /> */}
      <SectionWithAction />
    </div>
  )
}

export default Home
