import React from 'react'
import ReactPixel from 'react-facebook-pixel'

ReactPixel.pageView() // For tracking page view

const FAQ = () => {
  return (
    <div className={'terms-and-conditions-page'}>
      <div className="container-document">
        <h2 className="center">FAQ</h2>

        <h2 className="center mt-2">Gestione di un Paziente</h2>

        <hr />

        <h2 className="faq-title">Posso creare un anagrafica paziente?</h2>
        <p className="faq-p">
          Sì, tramite la sezione “Gestione del paziente” potrai creare dati
          anagrafici, raccogliere le firme per il trattamento dei dati
          sensibili, impostare i recall automatici per programmare i trattamenti
          futuri e consultare la scheda dedicata.
        </p>

        <h2 className="faq-title">
          Posso creare e gestire digitalmente le cartelle cliniche dei miei
          pazienti?
        </h2>
        <p className="faq-p">
          Sì, all’interno della scheda paziente è possibile creare sia la
          cartella anamnestica che quella relativa al singolo trattamento. Una
          volta finalizzate, queste saranno poi disponibili all’interno dello
          storico trattamenti in formato PDF.
        </p>

        <h2 className="faq-title">
          Le cartelle cliniche sono predefinite o personalizzabili?
        </h2>
        <p className="faq-p">
          Sì, tutte le cartelle, sia quella anamnestica che di trattamento, sono
          personalizzabili al 100%. Tramite la sezione “Impostazioni” avrai la
          possibilità di modificare le domande, aggiungerne di nuove o impostare
          risposte multiple.
        </p>
        <p className="faq-p">
          N.B. Per le sole categorie di Chirurgia Estetica e Dentale, sono
          previste delle speciali sezioni con funzionalità avanzate per il
          tratteggio dei modelli predefiniti del corpo e delle arcate dentali.
        </p>
        <p className="faq-p">
          Stiamo lavorando intensamente per espandere queste funzioni anche in
          altre specializzazioni.
        </p>

        <h2 className="faq-title">
          Quando creo la cartella trattamento, posso caricare le foto dell’area
          trattata?
        </h2>
        <p className="faq-p">
          Sì, una volta creata la cartella trattamento e selezionato la
          tipologia di servizio da effettuare, sarà possibile effettuare
          l’upload delle foto dello stato pre e post intervento e del risultato
          finale post guarigione.
        </p>

        <h2 className="faq-title">
          Posso programmare un piano di cura per il mio paziente?
        </h2>
        <p className="faq-p">
          Sì, all’interno della scheda paziente è possibile aggiungere
          appuntamenti programmati che verranno automaticamente inseriti nel
          calendario integrato della clinica.
        </p>

        <h2 className="faq-title">
          Posso consultare lo stato dei pagamenti dei pazienti?
        </h2>
        <p className="faq-p">
          Sì, all’interno della scheda paziente è possibile consultare lo
          storico dei pagamenti e verificare eventuali insoluti.{' '}
        </p>

        <h2 className="center mt-3">Contabilità</h2>

        <hr />

        <h2 className="faq-title">
          Posso creare e inviare fatture catacee ed elettroniche?
        </h2>
        <p className="faq-p">
          Sì, entrambe. Dopo aver effettuato un trattamento o un consulto,
          potrai creare la fattura del servizio erogato dalla sezione “Fatture e
          acquisti”. Dopo averla emessa, potrai scaricarla in PDF o inviarla via
          mail direttamente dalla piattaforma.
        </p>
        <p className="faq-p">
          Inoltre, una volta creata avrai la possibilità di inviarla
          elettronicamente al sistema di interscambio tramite la nostra
          piattaforma (servizio API di terze parti){' '}
        </p>

        <h2 className="faq-title">
          Posso creare una fattura con servizi di divisioni diverse?
        </h2>
        <p className="faq-p">
          No, la fatturazione viene gestita separatamente per ogni divisione
          così da far gestire al meglio gli strumenti di analisi.
        </p>

        <h2 className="faq-title">Posso creare note di credito?</h2>
        <p className="faq-p">
          Sì, nel caso in cui un servizio già fatturato non venga poi erogato, è
          possibile emettere nota credito dalla sezione “Fatture e acquisti”.
          Dopo averla emessa potrai scaricarla in PDF o inviarla via mail
          direttamente dalla piattaforma.
        </p>

        <h2 className="faq-title">Posso gestire i miei servizi?</h2>
        <p className="faq-p">
          Sì, all’interno della sezione “Impostazioni” potrai settare i tuoi
          servizi nella misura di: nome del servizio, descrizione, volume,
          prezzo e divisione/categoria.{' '}
        </p>
        <p className="faq-p">
          Pre-impostare i servizi velocizza la creazione delle fatture e delle
          cartelle cliniche.
        </p>

        <h2 className="faq-title">Posso creare e gestire i preventivi?</h2>
        <p className="faq-p">
          Sì, all’interno della sezione “Fatture e acquisti”, potrai creare
          quotazioni per i tuoi potenziali clienti. Dopo aver emesso il
          preventivo, potrai scaricarlo in PDF o inviarlo via mail direttamente
          dalla piattaforma.
        </p>
        <p className="faq-p">
          Inoltre, tramite la nostra funzione “Potenziali” potrai gestire i
          reminder da inviare ai pazienti che non hanno ancora acquistato il
          servizio.
        </p>

        <h2 className="faq-title">Posso gestire le aliquote?</h2>
        <p className="faq-p">
          Sì, all’interno della sezione “Impostazioni” potrai settare l’aliquota
          stabilita per la tua struttura societaria.
        </p>

        <h2 className="faq-title">
          Posso importare la mia vecchia contabilità?
        </h2>
        <p className="faq-p">
          Sì, acquistando il pacchetto per la migrazione (richiedi un
          preventivo), avrai la possibilità di importare fino 5 anni di
          contabilità pregressa.
        </p>
        <p className="faq-p">
          Ci occupiamo noi della migrazione, i formati supportati dalla nostra
          piattaforma sono: XML, XLS e CSV.
        </p>

        <h2 className="faq-title">Posso esportare i miei file contabili?</h2>
        <p className="faq-p">
          Sì, è possibile scaricare tutti file contabili in XML, XLS e CSV.
        </p>

        <h2 className="center mt-3">Gestione clinica</h2>

        <hr />

        <h2 className="faq-title">
          Posso gestire gli operatori della mia clinica?
        </h2>
        <p className="faq-p">
          Sì, tramite la sezione “Operatori” è possibile inserire nuovi membri
          del personale, dargli poteri, affidargli ruoli e assegnarli ad un
          reparto.
        </p>

        <h2 className="faq-title">
          Esiste una dashboard per monitorare le funzioni principali?
        </h2>
        <p className="faq-p">
          Sì, dalla sezione “Overview”, l’utente Admin, gli utenti autorizzati e
          il proprietario della licenza potranno gestire gli appuntamenti del
          giorno, monitorare l’andamento delle divisioni e del fatturato ed
          effettuare la ricerca veloce del paziente.
        </p>

        <h2 className="faq-title">
          Posso calendarizzare gli appuntamenti della mia clinica?
        </h2>
        <p className="faq-p">
          Sì, tramite il nostro “Calendario smart” potrai gestire gli
          appuntamenti, etichettandoli in base a divisione, trattamento e
          operatore designato.
        </p>
        <p className="faq-p">
          Inoltre, tramite la nostra funzione “Sala d’attesa” potrai ridurre lo
          spreco di tempo nella gestione del paziente.
        </p>

        <h2 className="faq-title">
          Posso gestire i miei pazienti in video chiamata?
        </h2>
        <p className="faq-p">
          Sì, tramite la nostra funzione avanzata di “Video consulto” è
          possibile calendarizzare ed effettuare sessioni di Telemedicina
          all’interno della stessa piattaforma e senza dover utilizzare
          strumenti esterni.
        </p>

        <h2 className="faq-title">
          Posso ricevere avvisi per non perdere gli eventi importanti?{' '}
        </h2>
        <p className="faq-p">
          Sì, tramite il nostro sistema di “Notifiche personalizzate” non
          perderai mai più tutte le notizie importanti sulla gestione della tua
          clinica.{' '}
        </p>
        <p className="faq-p">
          Riceverai avvisi su: contabilità, appuntamenti, gestione dei pazienti
          e telemedicina.{' '}
        </p>

        <h2 className="center mt-3">Sicurezza e conservazione dei dati</h2>

        <hr />

        <h2 className="faq-title">Come vengono conservati i dati?</h2>
        <p className="faq-p">
          ClinicSoul è una piattaforma interamente Cloud. L’archiviazione di
          tutti dati viene gestita tramite Microsoft Azure Cloud Storage.
        </p>

        <h2 className="faq-title">
          È necessario effettuare dei backup manualmente?
        </h2>
        <p className="faq-p">
          No, ClinicSoul garantisce backup costanti e automatici. In caso di
          bisogno, saremo noi stessi a fornire la copia di backup.
        </p>

        <h2 className="faq-title">ClinicSoul rispetta la normativa GDPR?</h2>
        <p className="faq-p">
          Sì, il nostro software rispetta a pieno la normativa GDPR.
        </p>

        <h2 className="center mt-3">Licenza e sottoscrizione</h2>

        <hr />

        <h2 className="faq-title">Come posso iscrivermi?</h2>
        <p className="faq-p">
          L’iscrizione può essere effettuata autonomamente tramite il nostro
          sito a questo link:{' '}
          <a href="https://clinicsoul.com/pricing">
            https://clinicsoul.com/pricing
          </a>
        </p>

        <h2 className="faq-title">
          Posso provare il software prima di acquistarlo?
        </h2>
        <p className="faq-p">
          Sì, è possibile testare la piattaforma gratuitamente per 14 giorni
          prima di acquistare la licenza.
        </p>

        <h2 className="faq-title">Come avviene il pagamento?</h2>
        <p className="faq-p">
          Le opzioni di pagamento sono su base mensile o annuale, entrambe le
          modalità vengono addebitate su una carta di credito da voi fornita.
        </p>

        <h2 className="faq-title">
          Il costo della licenza è comprensivo di tasse?
        </h2>
        <p className="faq-p">
          Sì, tutti i piani includono i costi di tassazione.
        </p>

        <h2 className="faq-title">
          Offrite dei servizi di set-up e migrazione?
        </h2>
        <p className="faq-p">
          Sì, proponiamo due pacchetti, uno per la migrazione dei dati dal
          precedente software e uno di set-up della piattaforma, al costo di
          600€ ciascuno. Acquistando entrambi i pacchetti si ha diritto al badge
          di priorità sull’assistenza in regalo (attesa max 48h per la risposta)
          del valore di 400€.
        </p>

        <h2 className="faq-title">Come posso disattivare la mia licenza?</h2>
        <p className="faq-p">
          Chiudere un account è semplicissimo, basta seguire la procedura dal
          pannello delle impostazioni della piattaforma.{' '}
        </p>
      </div>
    </div>
  )
}

export default FAQ
