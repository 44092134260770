export const first = {
  index: 1,
  img: '/iconography_one',
  video: '/site_mockup-1.mp4',
  list: ['', '', '', ''],
  noSub: true,
  poster: '/SCREEN_1.jpg',
}

export const second = {
  index: 2,
  img: '/iconography_two',
  imgClass: 'has-top-margin-25',
  video: '/site_mockup-3.mp4',
  list: ['', '', '', '', ''],
  poster: '/SCREEN_2.jpg',
}

export const third = {
  index: 3,
  img: '/iconography_three',
  video: '/site_mockup-7.mp4',
  list: ['', '', '', ''],
  poster: '/SCREEN_7.jpg',
}

export const fourth = {
  index: 4,
  img: '/iconography_three',
  video: '/site_mockup-5.mp4',
  list: ['', '', '', ''],
  poster: '/SCREEN_5.jpg',
}

export const fifth = {
  index: 5,
  img: '/iconography_three',
  video: '/site_mockup-4.mp4',
  poster: '/SCREEN_3.jpg',
}
