import React from 'react'

import './social_media_bar.scss'

const SocialMediaBar = () => {
    return (
        <ul className={'footer-social'}>
            <li className={'footer__icon icon-linkedin'}>
                <a
                    href="https://www.linkedin.com/company/clinicsoulsoftware/"
                    target={'_blank'}
                    rel="noopener noreferrer"
                >
                    {' '}
                </a>
            </li>
            <li className={'footer__icon icon-fb'}>
                <a href="https://www.facebook.com/clinicsoulsoftware/" target={'_blank'} rel="noopener noreferrer">
                    {' '}
                </a>
            </li>
            <li className={'footer__icon icon-ig'}>
                <a href="https://www.instagram.com"> </a>
            </li>
        </ul>
    )
}

export default SocialMediaBar
