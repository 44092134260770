import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import WrapperProvider from './components/WrapperProvider'
import ScrollToTop from './shared/ScrollToTop'

ReactDOM.render(
    <WrapperProvider>
        <React.StrictMode>
            <BrowserRouter>
                <ScrollToTop />
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </WrapperProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
