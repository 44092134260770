/* eslint-disable */
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import ReactPixel from 'react-facebook-pixel'

import { ContactContext } from '../../context/ContactContext'

import './form_contact.scss'

const optionsDropdown = [
  {
    value: 'generic_info',
    label: (
      <FormattedMessage
        id="form_contact.reasons.generic_info"
        defaultMessage="Generic Information"
      />
    ),
  },
  {
    value: 'pricing_info',
    label: (
      <FormattedMessage
        id="form_contact.reasons.pricing_info"
        d
        defaultMessage="Pricing Information"
      />
    ),
  },
  {
    value: 'security_info',
    label: (
      <FormattedMessage
        id="form_contact.reasons.security_info"
        d
        defaultMessage="Security Information"
      />
    ),
  },
  {
    value: 'all',
    label: (
      <FormattedMessage
        id="form_migration.data_type.option.4"
        defaultMessage="All"
      />
    ),
  },
]

const FormContact = () => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' })
  const { message } = useContext(ContactContext)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSubmit = async (data) => {
    // TODO: CATCH ERROR HANDLING ON SUBMIT FORM
    const { email, full_name, message, reason, telephone } = data

    const obj = {
      email,
      full_name,
      message,
      reason: reason.value,
      telephone,
    }

    const req = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}api/contact`,
      obj,
    )
    if (req.data) {
      ReactPixel.track('SubmitApplication') // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
      setIsSubmitted(true)
    }
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 52,
      minHeight: 52,
      borderColor: '#eaeaf3',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#2a2252',
    }),
    placeholder: (base) => {
      return {
        ...base,
        color: '#2a2252',
      }
    },
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  }

  const validateEmail = (value) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return true
    }
    return false
  }

  if (isSubmitted === true) {
    return (
      <p>
        <FormattedMessage
          id="form_contact.submitted"
          defaultMessage=" Thanks for submitting the form! We will get in touch soon."
        />
      </p>
    )
  } else
    return (
      <form className={'form-contact'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'flex-container flex-wrap flex-end'}>
          <div className={'form-field'}>
            <label
              className={'oval-circle-red oval--right'}
              htmlFor="full_name"
            >
              <FormattedMessage
                id="form_contact.full_name"
                defaultMessage="Full name "
              />
            </label>
            <input
              type="input"
              placeholder="E.g. John Doe"
              id="input_full_name"
              name="full_name"
              ref={register({ required: true, minLength: 2 })}
            />
            {errors.full_name && (
              <span className="error-validation">
                <FormattedMessage
                  id="form_contact.validation.full_name"
                  defaultMessage="Please insert a valid name (minimum 2 characters)"
                />
              </span>
            )}
          </div>
          <div className={'form-field'}>
            <label className={'oval-circle-red oval--right'} htmlFor="email">
              Email
            </label>
            <input
              type="input"
              placeholder="email@email.com"
              id="input_email"
              name="email"
              ref={register({ required: true, validate: validateEmail })}
            />
            {errors.email && (
              <span className="error-validation">
                <FormattedMessage
                  id="form_contact.validation.email"
                  defaultMessage="Please insert a valid email address"
                />
              </span>
            )}
          </div>
          <div className={'form-field'}>
            <label
              className={'oval-circle-red oval--right'}
              htmlFor="telephone"
            >
              <FormattedMessage
                id="form_contact.telephone"
                defaultMessage="Telephone Number "
              />
            </label>
            <input
              type="number"
              placeholder="your telephone number"
              id="input_telephone"
              name="telephone"
              ref={register({ required: true })}
            />
            {errors.telephone && (
              <span className="error-validation">
                {' '}
                <FormattedMessage
                  id="form_contact.validation.telephone"
                  defaultMessage="Please insert a valid telephone number"
                />
              </span>
            )}
          </div>
          <div className={'form-field form-field-dropdown'}>
            <label className={'oval-circle-red oval--right'} htmlFor="reason">
              <FormattedMessage
                id="form_contact.reason"
                defaultMessage="Reason for contact "
              />
            </label>
            <Controller
              as={Select}
              isSearchable={false}
              name="reason"
              options={optionsDropdown}
              control={control}
              defaultValue={optionsDropdown[0]}
              styles={customStyles}
            />
          </div>
          <div className={'form-field'} style={{ marginRight: 0 }}>
            <label className={'oval-circle-red oval--right'} htmlFor="Message">
              <FormattedMessage
                id="form_contact.message"
                defaultMessage="Your message"
              />
            </label>
            <textarea
              type="textarea"
              id="message_textarea"
              name="message"
              placeholder="Type here your message"
              ref={register}
              defaultValue={message}
            />
          </div>
        </div>
        <div className="flex-container flex-space-between">
          <p>
            <span className={'oval-circle-red oval--left'}></span>
            <FormattedMessage
              id="form_contact.required"
              defaultMessage="Required field"
            />
          </p>
          <button
            type="submit"
            className={`btn btn-md btn-magenta ${
              !isValid ? 'btn-invalid' : ''
            }`}
          >
            <FormattedMessage id="form_contact.cta" defaultMessage="Send" />
          </button>
        </div>
      </form>
    )
}

export default FormContact
