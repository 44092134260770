import React, { useState } from 'react'
import flatten from 'flat'

import { IntlProvider } from 'react-intl'
import Italian from '../../languages/it-IT.json'
import English from '../../languages/en-US.json'

export const Context = React.createContext()

const local = navigator.language

let lang
if (local === 'it-IT') {
  lang = Italian
} else {
  lang = English
}

const WrapperProvider = (props) => {
  const [locale, setLocale] = useState(local)
  const [messages, setMessages] = useState(lang)

  function selectLang(e) {
    const newLocale = e.value
    setLocale(newLocale)
    if (newLocale === 'it-IT') {
      setMessages(Italian)
    } else {
      setMessages(English)
    }
  }
  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={flatten(messages)} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

export default WrapperProvider
