import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-scroll'

import './navbar.scss'
import Button from '../../shared/Button'
import { useLocation } from 'react-router-dom'

const Navbar = ({ isMobile, onToggleMenu }) => {
  const location = useLocation()
  console.log(location.pathname)

  const isHome = location.pathname === '/'

  return (
    <nav>
      <div className={'hamburger-menu'} id="nav-icon3" onClick={onToggleMenu}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={'nav-inner'}>
        <NavLink to="/" exact activeClassName="active-link">
          <div className={'logo-container'}>
            <img className={'img-logo'} src="/logo_main.svg" alt="Main Logo" />
            {/* <span>BETA</span> */}
          </div>
        </NavLink>
        <ul className={'nav-menu'}>
          <li>
            {isMobile || !isHome ? (
              <NavLink to="/" exact activeClassName="active-link">
                <FormattedMessage id="nav.features" defaultMessage="Features" />
              </NavLink>
            ) : (
              <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={100}
                style={{ cursor: 'pointer' }}
              >
                <FormattedMessage id="nav.features" defaultMessage="Features" />
              </Link>
            )}
          </li>
          <li>
            {' '}
            <NavLink to="/pricing" exact activeClassName="active-link">
              <FormattedMessage id="nav.pricing" defaultMessage="Pricing" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact activeClassName="active-link">
              <FormattedMessage id="nav.contact" defaultMessage="Contact Us" />
            </NavLink>
          </li>
          <li>
            <a href="https://blog.clinicsoul.com/">Blog</a>
          </li>
        </ul>
        <div className={'button-area'}>
          <a
            href="https://dashboard.clinicsoul.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="transparent" size={'md'}>
              Log in
            </Button>
          </a>
          <a
            href="https://dashboard.clinicsoul.com/sign-up"
            target="_blank"
            className="color-white"
            rel="noopener noreferrer"
          >
            <Button variant="purple" size={'md'}>
              <FormattedMessage id="nav.cta_demo" defaultMessage="Get a demo" />
            </Button>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
