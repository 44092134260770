import React from 'react'
import { FormattedMessage } from 'react-intl'

import './services_grid.scss'

const ServicesGrid = ({ data }) => {
  return (
    <div className={'flex-container flex-mobile--col items-container'}>
      {data.map((el, i) => (
        <div key={i} className={'flex-item items-container__item'}>
          <div className="items-container__img-block">
            <div className="icon-service-container">
              <img
                src={el.img}
                alt={''}
                className={'icon-service hide-mobile'}
              />
              <video
                className={'video-service only-show-desktop'}
                autoPlay
                loop
                playsinline
                muted
                src={el.video}
              ></video>
            </div>
            <img
              src="/icon-check.svg"
              alt="icon check"
              className={'icon-check'}
            />
          </div>
          <h4>
            <FormattedMessage id={`services.${i + 1}.title`} />
          </h4>
          <p>
            <FormattedMessage
              id={`services.${i + 1}.para`}
              defaultMessage="service paragraph"
            />
          </p>
        </div>
      ))}
    </div>
  )
}

export default ServicesGrid
