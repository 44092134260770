import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Select from 'react-select'

import { Context } from '../WrapperProvider'

import SocialMediaBar from '../SocialMediaBar'

import './footer.scss'

const Footer = () => {
  const context = useContext(Context)

  let placeholderLang = 'Italiano'
  if (context.locale === 'en-US' || context.locale === 'en-GB') {
    placeholderLang = 'English'
  }

  const options = [
    { value: 'en-US', label: 'English' },
    { value: 'it-IT', label: 'Italiano' },
  ]

  const customStyles = {
    option: (base, state) => ({
      ...base,
      borderBottom: 'transparent',
      // color: '#2a2252',
      padding: 10,
      backgroundColor: state.isSelected ? '#2684ff' : null,
      cursor: 'pointer',
      border: 0,
    }),
    control: (base) => ({
      ...base,
      borderColor: 'transparent',
      cursor: 'pointer',
    }),
    placeholder: (base) => {
      return {
        ...base,
        color: '#2a2252',
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      color: '#2a2252',
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  }

  return (
    <footer className={'footer'}>
      <div className="flex-container flex-wrap">
        <div className={'geo-info'}>
          {/* <span className={'footer-geo'}>United Kingdom</span> */}
          <span className={'footer-language'}>
            <Select
              options={options}
              defaultValue={options[0]}
              onChange={context.selectLang}
              placeholder={placeholderLang}
              styles={customStyles}
              isSearchable={false}
            />
          </span>
        </div>
        <div className={'footer-list-container'}>
          <ul className={'footer-list'}></ul>
        </div>
        <div className={'footer-list-container'}>
          <ul className={'footer-list'}></ul>
        </div>
        <div className={'footer-list-container'}>
          <h5>Support</h5>
          <ul className={'footer-list'}>
            <li>
              {' '}
              <NavLink to="/contact" exact>
                Contact us
              </NavLink>
            </li>
            <li>
              {' '}
              <NavLink to="/privacy" exact>
                Privacy
              </NavLink>
            </li>
            <li>
              {' '}
              <NavLink to="/terms-and-conditions" exact>
                Terms and Conditions
              </NavLink>
            </li>
            <li>
              {' '}
              <NavLink to="/faq" exact>
                FAQ
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={'footer-list-container last-item'}>
          <h5>Contact</h5>
          <ul className={'footer-list'}>
            <li>
              <a href="mailto:info@clinicsoul.com">info@clinicsoul.com</a>{' '}
            </li>
            <li className={'footer-address'}>
              Kemp House 152 - 160, City Road<span>EC1V 2NX London</span>
            </li>
            <li>IT: +39 345 2731553</li>
            <li>UK: +44 7402448858</li>
            <SocialMediaBar />
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
