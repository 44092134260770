import React from 'react'
import { FormattedMessage } from 'react-intl'

import './title_with_form.scss'
import FormMigration from '../FormMigration'
import FormMigrationMobile from '../FormMigrationMobile'

const TitleWithForm = () => {
  return (
    <div className={'outer-lg  outer-white'}>
      <div className={'wrapper-inner  title-with-form'}>
        <h2 className={'section-title'}>
          {' '}
          <FormattedMessage
            id="form_migration.title"
            defaultMessage="Don’t worry about migrating your data into a new software, we can do it for you."
          />
        </h2>
        <div className="hide-desktop">
          <FormMigration />
        </div>
        <div className="hide-mobile">
          <FormMigrationMobile />
        </div>
      </div>
    </div>
  )
}

export default TitleWithForm
