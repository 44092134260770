import React from 'react'
import { FormattedMessage } from 'react-intl'

import './two_cols.scss'

const TwoCols = ({ className, data }) => {
  const { index, video, img, imgClass, list, noSub, poster } = data

  return (
    <div
      className={`container-mobile wrapper-inner two-cols-container ${className}`}
    >
      <div className={'flex-container flex-two-cols flex-mobile--col'}>
        <div className={'flex-item left-col'}>
          <h2 className={'section-title'}>
            {' '}
            <FormattedMessage id={`twoCols.${index}.header`} />
          </h2>
          <p style={{ marginBottom: '25px' }}>
            <b>
              <FormattedMessage id={`twoCols.${index}.subheader`} />
            </b>
          </p>
          <ul className={'check-list'}>
            {list &&
              list.map((el, i) => (
                <li>
                  <FormattedMessage
                    id={`twoCols.${index}.list.title.${i + 1}`}
                  />
                  {!noSub && (
                    <p>
                      <FormattedMessage
                        id={`twoCols.${index}.list.body.${i + 1}`}
                      />
                    </p>
                  )}
                </li>
              ))}
          </ul>
        </div>
        <div className={'flex-item'}>
          <img
            className={`righ-col-img ${
              imgClass ? imgClass : 'no-class'
            } only-show-mobile`}
            src={`${img}.jpg`}
            srcSet={`${img}@3x.jpg 1740w, ${img}@2x.jpg 1160w, ${img}.jpg 580w`}
            sizes={'(min-width:38em) 36vw, 100vw'}
            alt="iconography one"
          />
          <video
            className={'video-service only-show-desktop'}
            autoPlay
            loop
            muted
            webkit-playsinline
            playsinline
            src={video}
            preload
            style={{ maxWidth: 600 }}
            poster={poster}
          ></video>
        </div>
      </div>
    </div>
  )
}

export default TwoCols
