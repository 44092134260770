import React from 'react'
import ReactPixel from 'react-facebook-pixel'

ReactPixel.pageView() // For tracking page view

const Privacy = () => {
  return (
    <div className={'terms-and-conditions-page'}>
      <div className="container-document">
        <h2 className="center">Informativa sulla Privacy "ClinicSoul.com"</h2>

        <hr />

        <h2 className="min">Titolare del Trattamento</h2>
        <p>CLINICSOUL LTD</p>
        <p>
          Sede Legale: Kemp House, 152 - 160 City Road, London, EC1V 2NX, UNITED
          KINGDOM
        </p>
        <p>Registered in UNITED KINGDOM, Company Number 13152263</p>
        <p>Indirizzo e-mail: info@clinicsoul.com</p>

        <h2 className="min">Tipologia di dati trattati</h2>
        <ol>
          <li>
            <p>
              Dati Personali da te forniti volontariamente per accedere ai
              Servizi su www.ClinicSoul.com{' '}
            </p>
          </li>
        </ol>

        <p>
          Il Titolare raccoglie direttamente dai propri Utenti{' '}
          <strong>Dati Personali</strong> per fornire il Servizio e/o
          informazioni sul Servizio richiesto dagli Utenti (si tratta di dati
          quali il nome e cognome, l'indirizzo e-mail, il numero telefonico di
          contatto e altre informazioni inerenti il tipo di attività medica
          svolta).
        </p>
        <p>
          L'Utente si assume la responsabilità dei Dati Personali di terzi
          ottenuti, pubblicati o condivisi mediante www.ClinicSoul.com e
          garantisce di avere il diritto di comunicarli o diffonderli, liberando
          il Titolare da qualsiasi responsabilità verso terzi.
        </p>
        <ol>
          <li>
            <p>
              Dati di Utilizzo che vengono raccolti automaticamente quando
              utilizzi www.ClinicSoul.com{' '}
            </p>
          </li>
        </ol>

        <p>
          I sistemi informatici e le procedure software preposte al
          funzionamento del sito web www.ClinicSoul.com acquisiscono, nel corso
          del loro normale esercizio, alcuni Dati Personali la cui trasmissione
          è implicita nell'uso dei protocolli di comunicazione di Internet.
        </p>
        <ul>
          <li>
            <p>
              informazioni sui dispositivi utilizzati per accedere al sito web
              www.ClinicSoul.com (ad es. il tuo indirizzo IP, il tipo di sistema
              operativo utilizzato, o l’ID del browser);
            </p>
          </li>
          <li>
            <p>
              dati di log (ad es. file di log che rilevano quando un dispositivo
              accede ai nostri server, registrando la natura di ciascun accesso
              e l’indirizzo IP di origine);
            </p>
          </li>
          <li>
            <p>
              dati di utilizzo del sito web www.ClinicSoul.com (ad es. la data e
              l’ora di accesso e le attività svolte durante la navigazione);
            </p>
          </li>
          <li>
            <p>
              statistiche del sito web www.ClinicSoul.com (ad es. report sulla
              consegna o sull’apertura delle email inviate dai nostri Clienti).
            </p>
          </li>
        </ul>

        <p>
          Questi dati non sono accompagnati da alcuna informazione personale
          aggiuntiva e vengono utilizzati per:
        </p>

        <ul>
          <li>
            <p>ricavare informazioni statistiche anonime sull'uso del sito;</p>
          </li>
          <li>
            <p>
              gestire esigenze di controllo delle modalità di utilizzo dello
              stesso;
            </p>
          </li>
          <li>
            <p>
              accertare responsabilità in caso di ipotetici reati informatici;
            </p>
          </li>
          <li>
            <p>
              1. Dati Personali che vengono raccolti automaticamente quando ti
              inviamo e-mail o comunicazioni
            </p>
          </li>
          <li>
            <p>
              <strong>
                Quando inviamo email o comunicazioni usiamo sistemi di
                tracciamento, come ad esempio, per rilevare l’apertura di un
                messaggio, i click effettuati sui collegamenti ipertestuali
                contenuti all’interno dell’email, da quale indirizzo IP o con
                quale tipo di browser viene aperta l’email, e altri dettagli
                simili.
              </strong>
            </p>
          </li>
        </ul>

        <p>
          I Dati Personali possono essere liberamente forniti dall'Utente o, nel
          caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di
          www.ClinicSoul.com.
        </p>
        <p>
          Se non diversamente specificato, tutti i Dati Personali richiesti da
          www.ClinicSoul.com, sono obbligatori. Se l'Utente rifiuta di
          comunicarli, potrebbe essere impossibile per www.ClinicSoul.com
          fornire il Servizio.
        </p>

        <h2 className="min">Finalità del Trattamento dei Dati raccolti</h2>
        <p>
          I Dati dell'Utente sono raccolti per consentire al Titolare di{' '}
          <strong>fornire il proprio Servizio</strong>, così come per le
          seguenti finalità:
        </p>
        <ul>
          <li>
            <p>
              <strong>Statistica;</strong>
            </p>
          </li>
          <li>
            <p>
              <strong>Gestione contatti;</strong>
            </p>
          </li>
          <li>
            <p>
              <strong>Invio di messaggi ed email;</strong>
            </p>
          </li>
        </ul>
        <p>
          Per ottenere ulteriori informazioni dettagliate sulle finalità del
          trattamento e sui Dati Personali concretamente rilevanti per ciascuna
          finalità, l'Utente può fare riferimento alle relative sezioni di
          questo documento.
        </p>

        <h2 className="min">Base giuridica</h2>
        <p>
          <strong>Dati generati dall'accesso al sito e finalità:</strong>
        </p>
        <p>
          La base giuridica che legittima il trattamento di tali dati da parte
          del Titolare è la necessità di rendere utilizzabili le funzionalità
          del sito web ClinicSoul.com a seguito dell'accesso dell'Utente.
        </p>
        <p>
          <strong>Dati forniti volontariamente dall'Utente:</strong>
        </p>
        <p>
          La base giuridica che legittima il trattamento di tali dati da parte
          del Titolare è il consenso prestato dall'Utente per le finalità
          specifiche.
        </p>
        <p>
          In ogni caso il Titolare può trattare i Dati Personali riguardanti
          l’Utente nel caso sussista una delle seguenti condizioni:
        </p>
        <ul>
          <li>
            <p>
              il trattamento è necessario per adempiere un obbligo legale al
              quale è soggetto il Titolare;
            </p>
          </li>
          <li>
            <p>
              il trattamento è necessario per l'esecuzione di un compito di
              interesse pubblico o per l'esercizio di pubblici poteri di cui è
              investito il Titolare;
            </p>
          </li>
          <li>
            <p>
              il trattamento è necessario per il perseguimento del legittimo
              interesse del Titolare o di terzi.
            </p>
          </li>
        </ul>

        <h2 className="min">
          Modalità di trattamento e tempo di conservazione dei Dati Personali
        </h2>
        <p>
          I Dati Personali raccolti saranno trattati mediante strumenti
          elettronici o comunque automatizzati, informatici e telematici, o
          mediante elaborazioni manuali con logiche strettamente correlate alle
          finalità per le quali i Dati Personali sono stati raccolti e,
          comunque, in modo da garantire in ogni caso la sicurezza dei medesimi.
        </p>
        <p>
          I Dati Personali sono conservati per il tempo strettamente necessario
          alla gestione delle finalità per le quali i dati stessi sono raccolti
          nel rispetto delle norme vigenti e degli obblighi di legge.
        </p>
        <p>
          A seguito della richiesta di cancellazione da parte di un Utente,
          tutti i Dati Personali dello stesso verranno cancellati, fatta salva
          l'ulteriore conservazione se prevista da obblighi normativi e di
          legge.
        </p>
        <p>
          Il Titolare attua una politica di conservazione dei Dati Personali che
          limita il tempo di conservazione ad{' '}
          <strong>un massimo di 2 anni</strong> nel rispetto del principio di
          minimizzazione del trattamento dei dati.
        </p>
        <p>
          In ogni caso, abbiamo adottato procedure che vietano la conservazione
          dei Dati Personali a tempo indeterminato e, pertanto, limitiamo il
          tempo di conservazione, nel rispetto del principio di minimizzazione
          del trattamento dei dati.
        </p>

        <h2 className="min">Luogo</h2>
        <p>
          I Dati Personali sono trattati presso le sedi operative del Titolare
          ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano
          localizzate.
        </p>
        <p>
          I Dati Personali dell'Utente potrebbero essere trasferiti in un paese
          diverso da quello in cui l'Utente si trova ma sempre all'interno di
          Stati dell'Unione Europea.
        </p>
        <p>
          Resta in ogni caso inteso che il Titolare, ove si rendesse necessario,
          avrà facoltà di spostare i server anche all'esterno di Stati
          dell'Unione Europea. In tal caso, il Titolare assicura sin d'ora che
          il trasferimento dei Dati Personali extra-UE avverrà in conformità
          alle disposizioni di legge applicabili, previa stipula delle clausole
          contrattuali standard previste dalla Commissione Europea.
        </p>
        <p>Per ulteriori informazioni, contatta il Titolare.</p>

        <h2 className="min">
          Soggetti autorizzati al trattamento, responsabili e comunicazione dei
          Dati Personali
        </h2>
        <p>
          Il trattamento dei dati raccolti è eseguito da personale interno del
          Titolare a tal fine individuato e autorizzato del trattamento secondo
          specifiche istruzioni impartite nel rispetto della normativa vigente.
        </p>
        <p>
          I Dati Personali raccolti, qualora sia necessario o strumentale per
          l'esecuzione delle indicate finalità, potranno essere trattati da
          terzi nominati Responsabili del Trattamento, ovvero, a seconda dei
          casi, comunicati agli stessi quali autonomi titolari, e precisamente:
        </p>
        <ul>
          <li>
            <p>
              Persone fisiche, società, associazioni o studi professionali che
              prestino attività di assistenza e consulenza al Titolare;
            </p>
          </li>
          <li>
            <p>
              Società, enti, associazioni che svolgono servizi connessi e
              strumentali all'esecuzione delle finalità sopra indicate (servizio
              di analisi e ricerche di mercato, manutenzione dei sistemi
              informatici);
            </p>
          </li>
          <li>
            <p>
              Società esterne che il Titolare utilizza per gestire, per proprio
              conto, i dati dell'Utente per scopi quali: messaggi di notifica
              relativi ai servizi offerti dal Titolare e la verifica della
              correttezza dell'indirizzo di posta elettronica fornito in fase di
              registrazione, analisi statistiche, utilizzo di piattaforme di
              marketing, servizi IT per l'archiviazione dei dati e per
              l'assistenza clienti;
            </p>
          </li>
        </ul>

        <h2 className="min">Diritti dell'Utente</h2>
        <p>
          Ai sensi del Regolamento Europeo 679/2016 (GDPR) e della normativa
          nazionale, l'Utente può, secondo le modalità e nei limiti previsti
          dalla vigente normativa esercitare i seguenti diritti:
        </p>
        <ul>
          <li>
            <p>conoscerne l'origine;</p>
          </li>
          <li>
            <p>riceverne comunicazione intelligibile;</p>
          </li>
          <li>
            <p>
              avere informazioni circa la logica, le modalità e le finalità del
              trattamento;
            </p>
          </li>
          <li>
            <p>
              richiederne l'aggiornamento, la rettifica, l'integrazione, la
              cancellazione, la trasformazione in forma anonima, il blocco dei
              dati trattati in violazione di legge, ivi compresi quelli non più
              necessari al perseguimento degli scopi per i quali sono stati
              raccolti;
            </p>
          </li>
          <li>
            <p>
              nei casi di trattamento basato su consenso, ricevere i propri dati
              forniti al titolare, in forma strutturata e leggibile da un
              elaboratore di dati e in un formato comunemente usato da un
              dispositivo elettronico;
            </p>
          </li>
          <li>
            <p>
              il diritto di presentare un reclamo all'Autorità di controllo;
            </p>
          </li>
        </ul>

        <h2 className="min">Come esercitare i diritti dell'Utente</h2>
        <p>
          Per esercitare i diritti dell'Interessato, gli Utenti possono
          indirizzare una richiesta inviando un'e-mail all'indirizzo{' '}
          <strong>info@clinicsoul.com</strong>
        </p>
        <p>
          Le richieste saranno gestite dal Titolare nel più breve tempo
          possibile, in ogni caso entro un mese.
        </p>

        <h2 className="min">Difesa in giudizio</h2>
        <p>
          I Dati Personali dell'Interessato possono essere utilizzati da parte
          del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
          instaurazione per la difesa da abusi nell'utilizzo del sito web
          ClinicSoul.com o dei Servizi connessi da parte dell'Interessato.
        </p>
        <p>
          L'Interessato dichiara di essere consapevole che il Titolare potrebbe
          essere obbligato a rivelare i Dati Personali per ordine delle autorità
          pubbliche.
        </p>

        <h2 className="min">Modifiche a questa Informativa Privacy</h2>
        <p>
          Il Titolare si riserva il diritto di apportare modifiche alla presente
          privacy policy in qualunque momento dandone informazione agli
          Interessati su questa pagina e, se possibile, direttamente sul sito
          web ClinicSoul.com nonchè, qualora tecnicamente e legalmente
          fattibile, inviando una notifica agli Interessati attraverso uno degli
          estremi di contatto di cui è in possesso il Titolare. Si prega dunque
          di consultare regolarmente questa pagina, facendo riferimento alla
          data di ultima modifica indicata in fondo.
        </p>
        <h2>ALTRE INFORMAZIONI</h2>

        <h2 className="min">Dettagli sul trattamento dei Dati Personali</h2>
        <p>
          I Dati Personali sono raccolti per le seguenti finalità ed utilizzando
          i seguenti servizi:
        </p>
        <p>
          <strong>I. CONTATTARE L'UTENTE</strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>Contatto via telefono e sms</strong>
            </p>
          </li>
        </ul>
        <p>
          Gli Utenti che hanno fornito il proprio numero di telefono potrebbero
          essere contattati da ClinicSoul.com per la gestione della loro
          richiesta e per soddisfare richieste di supporto.
        </p>
        <p>Dati Personali raccolti: numero di telefono;</p>
        <p>
          <strong>I. Newsletter</strong>
        </p>
        <p>
          Con la registrazione alla newsletter, l'indirizzo email dell'Utente
          viene automaticamente inserito in una lista di contatti a cui potranno
          essere trasmessi messaggi email contenenti informazioni relative ai
          Servizi offerti da ClinicSoul.com
        </p>
        <p>Dati Personali raccolti: email</p>

        <h2 className="min">STATISTICA</h2>
        <p>
          I Servizi contenuti nella presente sezione permettono al Titolare di
          monitorare e analizzare i dati di traffico e servono a tener traccia
          del comportamento degli Utenti.
        </p>
        <p>I. Google Analytics con IP Anonimizzato</p>
        <p>
          Google Analytics è un servizio di analisi web fornito da Google Inc.
          ("Google"). Google utilizza i Dati Personali raccolti allo scopo di
          tracciare ed esaminare l'utilizzo del sito web ClinicSoul.com,
          compilare report e condividerli con gli altri servizi sviluppati da
          Google. Google potrebbe utilizzare i Dati Personali per
          contestualizzare e personalizzare gli annunci del proprio network
          pubblicitario.
        </p>
        <p>
          Questa integrazione di Google Analytics rende anonimo il tuo indirizzo
          IP. L'anonimizzazione funziona abbreviando entro i confini degli Stati
          Membri dell'Unione Europea o in altri Paesi aderenti all'accordo sullo
          Spazio Economico Europeo l'indirizzo IP degli Utenti e Studi Legali.
          Solo in casi eccezionali, l'indirizzo IP sarà inviato ai server di
          Google ed abbreviato all'interno degli Stati Uniti.
        </p>
        <p>Sede Legale: USA</p>
        <p>Di seguito il link alla Policy Privacy:</p>
        <a href="http://www.google.com/intl/it/policies/privacy/">
          http://www.google.com/intl/it/policies/privacy/
        </a>
        <p>Monitoraggio conversioni di Google Adwords</p>
        <p>
          Il monitoraggio conversioni di Google AdWords è un servizio di
          statistiche fornito da Google Inc. che collega i dati provenienti dal
          network di annunci Google AdWords con le azioni compiute all'interno
          del sito web ClinicSoul.com
        </p>
        <p>Sede Legale: USA</p>
        <p>Di seguito il link alla Policy Privacy:</p>
        <a href="http://www.google.com/intl/it/policies/privacy/">
          http://www.google.com/intl/it/policies/privacy/
        </a>

        <h2 className="min">HOSTING ED INFRASTRUTTURA BACKEND</h2>
        <p>
          Questo tipo di servizio ha la funzione di ospitare dati e file che
          permettono al sito web ClinicSoul.com di funzionare, ne consentono la
          distribuzione e mettono a disposizione un'infrastruttura pronta
          all'uso per erogare specifiche funzionalità del sito web
          ClinicSoul.com.
        </p>
        <p>
          Alcuni di questi servizi funzionano attraverso server dislocati
          geograficamente in luoghi differenti, rendendo difficile la
          determinazione del luogo esatto in cui vengono conservati i Dati
          Personali.
        </p>
        <p>
          <strong>Microsoft Azure</strong> è una piattaforma che consente al
          Titolare di sviluppare, far funzionare ed ospitare il sito web
          ClinicSoul.com
        </p>

        <p>Di seguito il link alla Policy Privacy:</p>

        <h2 className="min">DEFINIZIONI</h2>
        <ul>
          <li>
            <p>
              <strong>"Dati Personali"</strong> - qualsiasi informazione
              riguardante una persona fisica identificata o identificabile
              («interessato»); con particolare riferimento a un identificativo
              come il nome, un numero di identificazione, dati relativi
              all'ubicazione, un identificativo online o a uno o più elementi
              caratteristici della sua identità fisica, fisiologica, genetica,
              psichica, economica, culturale o sociale;
            </p>
          </li>
          <li>
            <p>
              <strong>"Utente"</strong> - l'individuo che utilizza il sito web
              ClinicSoul.com che, salvo ove diversamente specificato, coincide
              con l'Interessato;
            </p>
          </li>
          <li>
            <p>
              <strong>"Interessato"</strong> - la persona fisica cui si
              riferiscono i Dati Personali;
            </p>
          </li>
          <li>
            <p>
              <strong>"Titolare del Trattamento"</strong> - la persona fisica o
              giuridica, l'autorità pubblica, il servizio o altro organismo che,
              singolarmente o insieme ad altri, determina le finalità e i mezzi
              del trattamento di dati personali e gli strumenti adottati, ivi
              comprese le misure di sicurezza relative al funzionamento ed alla
              fruizione del sito web ClinicSoul.com;
            </p>
          </li>
          <li>
            <p>
              <strong>"Responsabile del Trattamento"</strong> - la persona
              fisica, giuridica, la pubblica amministrazione e qualsiasi altro
              ente che tratta dati personali per conto del Titolare;
            </p>
          </li>
          <li>
            <p>
              <strong>"ClinicSoul.com"</strong> - lo strumento hardware o
              software mediante il quale sono raccolti e trattati i Dati
              Personali degli Utenti;
            </p>
          </li>
          <li>
            <p>
              <strong>"Servizio"</strong> - il servizio/servizi forniti dal sito
              web ClinicSoul.com così come definiti nei relativi termini e
              condizioni di utilizzo;
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Privacy
