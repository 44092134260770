import React from 'react'

import ServicesGrid from '../ServicesGrid'
import { FormattedMessage } from 'react-intl'

import './containerinfo.scss'

const data = [
  {
    img: '/medical-01.svg',
    video: '/site_icon-1.mp4',
    title: 'Document and Treatment Records ',
    para:
      'Efficient data entry and registration, reliable and GDPR compliant. Register treatment records, consultation and reviews, add customisable fields, and safely transmit and store your patients’ data.',
  },
  {
    img: '/checklist-01.svg',
    video: '/site_icon-2.mp4',
    title: 'Scheduling and Calendar Overview',
    para:
      ' Quick and effective scheduling system to manage all appointments through one simple, clean calendar system. Check your agenda at a glance and say goodbye to empty slots through an integrated online scheduling tool. ',
  },
  {
    img: '/laptop-01.svg',
    video: '/site_icon-3.mp4',
    title: 'Optimized Patient Journey',
    para:
      'Improved communication processes to help you put your patients first. Deliver relevant and timely information, offers and reminders through ready-to-use marketing automations.',
  },
  {
    img: '/device-01.svg',
    video: '/site_icon-4.mp4',
    title: 'Billing and Invoicing',
    para:
      'Quick and easy invoices, expenses, and payments management and tracking. Measure performance in real time and improve your cash flow.',
  },
]

const ContainerInfo = () => {
  return (
    <div
      className={'container container-mobile outer outer--gray outer-padding'}
      id="section2"
    >
      <div className={'wrapper-inner'}>
        <h2 className={'section-header'}>
          <FormattedMessage
            id="containerinfo.title"
            defaultMessage="All the tools you need to keep everything on track, in one place"
          />
        </h2>
        <ServicesGrid data={data} />
      </div>
    </div>
  )
}

export default ContainerInfo
