import React from 'react'
import { FormattedMessage } from 'react-intl'

import FormContact from '../FormContact'
import SocialMediaBar from '../SocialMediaBar'

import './block_with_form.scss'

const BlockWithForm = () => {
    return (
        <div className={'outer-lg  outer-white'} style={{ marginBottom: 0 }}>
            <div className={'wrapper-inner bg-blue wrapper-rounded title-with-form block-with-form'}>
                <div className={'flex-container flex-two-cols flex-mobile--col'}>
                    <div className={'flex-item left-col'}>
                        <h2 className={'section-title'}>
                            <FormattedMessage id="form_contact.title" defaultMessage="Want to get in touch with us?" />
                        </h2>
                        <p className={'sub-text'}>
                            <FormattedMessage
                                id="form_contact.subtitle"
                                defaultMessage="If you need any help with our products and services, or you just want to share your feedback and ideas, fill the form. Our team will get back to you as soon as possible."
                            />
                        </p>
                        <div className="social-area">
                            <span className={'span-info'}>Social</span>
                            <SocialMediaBar />
                        </div>
                        <span className={'span-info email-info'}>
                            <FormattedMessage id="form_contact.contact_us" defaultMessage="Send an email to " />
                        </span>
                        <a href="mailto:info@clinicsoul.com" className={'contact-email'}>
                            info@clinicsoul.com
                        </a>
                    </div>
                    <div className={'flex-item'}>
                        <FormContact />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockWithForm
