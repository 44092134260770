import React from 'react'
import { FormattedMessage } from 'react-intl'

import './section_with_action.scss'
import Button from '../../shared/Button'

const SectionWithAction = () => {
  return (
    <div className={'wrapper-inner outer--gray wrapper-rounded'}>
      <div className="section-with-action-wrapper">
        <h2 className={'section-title'}>
          <FormattedMessage
            id="sectionWithAction.title"
            defaultMessage=" The right tools can make things even better than you ever imagined."
          />
        </h2>
        <p className={'section-sub'}>
          <FormattedMessage
            id="sectionWithAction.para"
            defaultMessage="Experience first-hand the leading clinic and practice management software"
          />
        </p>
        <a
          href="https://dashboard.clinicsoul.com/sign-up"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="magenta" size={'lg'}>
            <FormattedMessage
              id="sectionWithAction.cta"
              defaultMessage="Get your free trial"
            />
          </Button>
        </a>
      </div>
    </div>
  )
}

export default SectionWithAction
