import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { FormattedMessage } from 'react-intl'

import PriceCard from '../../components/PriceCard'
import PriceColumn from '../../components/PriceColumn'

import TitleWithForm from '../../components/TitleWithForm'

ReactPixel.pageView() // For tracking page view

const Pricing = () => {
  return (
    <div className={'pricing-page'}>
      <div className={'outer-lg'}>
        <div
          className={
            'wrapper-inner bg-blue wrapper-rounded title-with-form block-with-form'
          }
        >
          <div className={'flex-container flex-space-between'}>
            <div className={'flex-col'}>
              <PriceCard
                title={'Basic'}
                subText={
                  <FormattedMessage
                    id={`pricing_cols.basic.price`}
                    defaultMessage="Free (forever)"
                  />
                }
                btnText={
                  <FormattedMessage
                    id={`pricing_cols.start`}
                    defaultMessage="Get started"
                  />
                }
              />
              <PriceColumn type={'basic'} nrOfItems={6} />
            </div>
            <div className={'flex-col'}>
              <PriceCard
                title={'Prime'}
                price={'50'}
                btnText={
                  <FormattedMessage
                    id={`pricing_cols.start`}
                    defaultMessage="Get started"
                  />
                }
              />
              <PriceColumn type={'prime'} nrOfItems={6} />
            </div>
            <div className={'flex-col'}>
              <PriceCard
                title={'Pro'}
                price={'75'}
                btnText={
                  <FormattedMessage
                    id={`pricing_cols.start`}
                    defaultMessage="Get started"
                  />
                }
              />
              <PriceColumn type={'pro'} nrOfItems={6} />
            </div>
            <div className={'flex-col'}>
              <PriceCard
                className={'purple-bg'}
                title={'Enterprise'}
                subText={
                  <FormattedMessage
                    id={`pricing_cols.enterprise_label`}
                    defaultMessage="Ideal for large organizations"
                  />
                }
                btnText={
                  <FormattedMessage
                    id={`pricing_cols.get_quote`}
                    defaultMessage="Get a quote"
                  />
                }
              />
              <PriceColumn type={'enterprise'} nrOfItems={6} />
            </div>
          </div>
        </div>
      </div>
      <TitleWithForm />
    </div>
  )
}

export default Pricing
