import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ContactContext } from '../../context/ContactContext'

import { useForm } from 'react-hook-form'
import './form_migration.scss'

const convertData = (pars) => {
  if (pars === 'one_year') return '1 anno'
  else if (pars === 'two_years') return '2 anni'
  else if (pars === 'three_years') return '3 anni'
  else if (pars === 'four_years') return '4 anni'
  else if (pars === 'four_years') return '5 anni'
  else if (pars === 'accounting') return 'contabilitá'
  else if (pars === 'clinic_records') return 'cartelle cliniche'
  else if (pars === 'database') return 'data pazienti'
  else if (pars === 'all') return 'tutto'
  else if (pars === 'all_files') return 'CSV e XML'
  else return pars
}

const FormMigration = () => {
  const { setMessage } = useContext(ContactContext)

  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()

  const onSubmit = async (data) => {
    // const signup = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}mailer/docs-migration`, { data })
    console.log(data, 'SIGNUP')
    const { software, docs_years, docs, file_type } = data
    const msg = `Salve, vorrei ricevere maggiori informazioni sulla migrazione dal software ${software} a Clinicsoul. Ho ${convertData(
      docs_years,
    )} anni di ${convertData(docs)} in formato ${convertData(file_type)}.
        `
    setMessage(msg)
    history.push('/contact')
  }

  return (
    <form className={'form-migration'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex-container flex-wrap'}>
        <div className={'form-field'}>
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.from_which_software"
              defaultMessage="From which software you are switching?"
            />
          </p>
          <FormattedMessage id="form_migration.software_name">
            {(msg) => (
              <input
                type="text"
                placeholder={msg}
                name="software"
                ref={register({ required: true, minLength: 2 })}
                className={`${errors.software ? 'field-error' : 'no-error'}`}
                style={{ border: '1px solid black' }}
              />
            )}
          </FormattedMessage>
          {errors.software && (
            <p className={'field-error-label'}>
              {' '}
              <FormattedMessage
                id="form_migration.from_which_software_err"
                defaultMessage="Please enter name of the software or mention 'unknown'"
              />
            </p>
          )}
        </div>
        <div className={'form-field'}>
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.docs_years.label"
              defaultMessage="How old is the billing history you need to import?"
            />
          </p>
          <div className={'form-radio-area short-btn'}>
            <div className="radiobtn">
              <input
                type="radio"
                id="one_year"
                name="docs_years"
                value="one_year"
                ref={register}
              />
              <label htmlFor="one_year">
                {' '}
                <FormattedMessage
                  id="form_migration.docs_years.option.1"
                  defaultMessage="1 Year"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="two_years"
                name="docs_years"
                value="two_years"
                ref={register}
              />
              <label htmlFor="two_years">
                <FormattedMessage
                  id="form_migration.docs_years.option.2"
                  defaultMessage="2 Years"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="three_years"
                name="docs_years"
                value="three_years"
                ref={register}
              />
              <label htmlFor="three_years">
                <FormattedMessage
                  id="form_migration.docs_years.option.3"
                  defaultMessage="3 Years"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="four_years"
                name="docs_years"
                value="four_years"
                ref={register}
              />
              <label htmlFor="four_years">
                <FormattedMessage
                  id="form_migration.docs_years.option.4"
                  defaultMessage="4 Years"
                />
              </label>
            </div>

            <div className="radiobtn">
              <input
                type="radio"
                id="five_years"
                name="docs_years"
                value="five_years"
                ref={register}
              />
              <label htmlFor="five_years">
                <FormattedMessage
                  id="form_migration.docs_years.option.5"
                  defaultMessage="5 Years"
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'form-field'}>
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.data_type.label"
              defaultMessage="What kind of data and documents you need to import?"
            />
          </p>
          <div className={'form-radio-area'}>
            <div className="radiobtn">
              <input
                type="radio"
                id="accounting"
                name="docs"
                value="accounting"
                ref={register}
              />
              <label htmlFor="accounting">
                {' '}
                <FormattedMessage
                  id="form_migration.data_type.option.1"
                  defaultMessage="Accounting records"
                />
              </label>
            </div>
            <div className="radiobtn has-alignment">
              <input
                type="radio"
                id="clinic_records"
                name="docs"
                value="clinic_records"
                ref={register}
              />
              <label htmlFor="clinic_records">
                {' '}
                <FormattedMessage
                  id="form_migration.data_type.option.2"
                  defaultMessage="Patients EHR records"
                />
              </label>
            </div>
            <div className="radiobtn has-alignment">
              <input
                type="radio"
                id="database"
                name="docs"
                value="database"
                ref={register}
              />
              <label htmlFor="database">
                {' '}
                <FormattedMessage
                  id="form_migration.data_type.option.3"
                  defaultMessage="Patients data"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="all"
                name="docs"
                value="all"
                ref={register}
              />
              <label htmlFor="all">
                {' '}
                <FormattedMessage
                  id="form_migration.data_type.option.4"
                  defaultMessage="All"
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'form-field lg-label'}>
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.docs_format.label"
              defaultMessage="Which is the format of files you need to import?"
            />
          </p>
          <div className={'form-radio-area'}>
            <div className="radiobtn">
              <input
                type="radio"
                id="xml"
                name="file_type"
                value="xml"
                ref={register}
              />
              <label htmlFor="xml">
                {' '}
                <FormattedMessage
                  id="form_migration.docs_format.option.1"
                  defaultMessage="XML"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="csv"
                name="file_type"
                value="csv"
                ref={register}
              />
              <label htmlFor="csv">
                <FormattedMessage
                  id="form_migration.docs_format.option.2"
                  defaultMessage="CSV"
                />
              </label>
            </div>
            <div className="radiobtn">
              <input
                type="radio"
                id="all_files"
                name="file_type"
                value="all_files"
                ref={register}
              />
              <label htmlFor="all_files">
                <FormattedMessage
                  id="form_migration.docs_format.option.3"
                  defaultMessage="All"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" className={'btn btn-lg btn-magenta'}>
        <FormattedMessage
          id="form_migration.cta"
          defaultMessage="Get a quote"
        />
      </button>
    </form>
  )
}

export default FormMigration
