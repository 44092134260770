import React from 'react'
import { FormattedMessage } from 'react-intl'

import './two_cols_with_img.scss'

const TwoColsWithImg = () => {
  return (
    <div
      className={
        'container-mobile outer-lg outer-pink outer-padding--variant two-cols-container--with-img'
      }
    >
      <div className={'wrapper-inner'}>
        <div
          className={
            'flex-container flex-two-cols flex-mobile--col flex-cols-reverse'
          }
        >
          <div className={'left-col'}>
            <h2 className={'section-title section-title--20-bottom'}>
              <FormattedMessage
                id="twoColsWithImg.title"
                defaultMessage="No need to worry, we will always be by your side"
              />
            </h2>
            <p>
              <FormattedMessage
                id="twoColsWithImg.firstPara"
                defaultMessage="Our team of professionals will support you every step of the way to get the most value from Clinic Soul ensuring that everything is always crystal clear, for you and your team."
              />
            </p>
            <p>
              <FormattedMessage
                id="twoColsWithImg.secondPara"
                defaultMessage=" ClinicSoul is the perfect combination of advanced functionality and accessible design,
                                created by highly qualified software and digital marketing professionals and healthcare
                                practitioners, just like you."
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColsWithImg
