import React, { useState } from 'react'

import './button.scss'

const Button = (props) => {
    const [size] = useState(props.size)
    const [variant] = useState(props.variant)
    return (
        <button className={`btn btn-${variant} btn-${size}`} onClick={props.handleClick}>
            {props.children}
        </button>
    )
}
export default Button
