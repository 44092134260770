import React from 'react'
import ReactPixel from 'react-facebook-pixel'

import BlockWithForm from '../../components/BlockWithForm'

ReactPixel.pageView() // For tracking page view

const Contact = () => {
  return (
    <div className={'contact-page'}>
      <BlockWithForm />
    </div>
  )
}

export default Contact
