import React from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'

import { useForm, Controller } from 'react-hook-form'
import './form_migration_mobile.scss'

const optionsYears = [
  {
    value: 'one_year',
    label: (
      <FormattedMessage
        id="form_migration.docs_years.option.1"
        defaultMessage="1 Year"
      />
    ),
  },
  {
    value: 'two_years',
    label: (
      <FormattedMessage
        id="form_migration.docs_years.option.2"
        defaultMessage="2 Years"
      />
    ),
  },
  {
    value: 'three_years',
    label: (
      <FormattedMessage
        id="form_migration.docs_years.option.3"
        defaultMessage="3 Years"
      />
    ),
  },
  {
    value: 'four_years',
    label: (
      <FormattedMessage
        id="form_migration.docs_years.option.4"
        defaultMessage="4 Years"
      />
    ),
  },
  {
    value: 'five_years',
    label: (
      <FormattedMessage
        id="form_migration.docs_years.option.5"
        defaultMessage="5 Years"
      />
    ),
  },
]

const docsType = [
  {
    value: 'accounting',
    label: (
      <FormattedMessage
        id="form_migration.data_type.option.1"
        defaultMessage="Accounting records"
      />
    ),
  },
  {
    value: 'clinic_records',
    label: (
      <FormattedMessage
        id="form_migration.data_type.option.2"
        defaultMessage="Patients EHR records"
      />
    ),
  },
  {
    value: 'database',
    label: (
      <FormattedMessage
        id="form_migration.data_type.option.3"
        defaultMessage="Patients data"
      />
    ),
  },
  {
    value: 'all',
    label: (
      <FormattedMessage
        id="form_migration.data_type.option.4"
        defaultMessage="All"
      />
    ),
  },
]

const fileType = [
  {
    value: 'xml',
    label: (
      <FormattedMessage
        id="form_migration.docs_format.option.1"
        defaultMessage="XML"
      />
    ),
  },
  {
    value: 'csv',
    label: (
      <FormattedMessage
        id="form_migration.docs_format.option.2"
        defaultMessage="CSV"
      />
    ),
  },
  {
    value: 'all',
    label: (
      <FormattedMessage
        id="form_migration.docs_format.option.3"
        defaultMessage="All"
      />
    ),
  },
]

const FormMigrationMobile = () => {
  const { register, handleSubmit, errors, control } = useForm()

  const onSubmit = async (data) => {
    console.log(data, 'DATAA')
    const signup = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}mailer/docs-migration`,
      { data },
    )
    console.log(signup)
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 52,
      minHeight: 52,
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'form-migration-mobile'}>
      <div className={'flex-container flex-wrap'}>
        <div className={'form-field-mobile'}>
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.from_which_software"
              defaultMessage="From which software you are switching?"
            />
          </p>
          <input
            type="text"
            placeholder="Name of the software"
            name="software"
            ref={register({ required: true, minLength: 2 })}
            className={`${errors.software ? 'field-error' : 'no-error'}`}
          />
          {errors.software && (
            <p className={'field-error-label'}>
              {' '}
              <FormattedMessage
                id="form_migration.from_which_software_err"
                defaultMessage="Please enter name of the software or mention 'unknown'"
              />
            </p>
          )}
        </div>

        <div className="form-field-mobile">
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.docs_years.label"
              defaultMessage="How old is the billing history you need to import?"
            />
          </p>
          <Controller
            as={Select}
            isSearchable={false}
            name="nr_years"
            options={optionsYears}
            control={control}
            defaultValue={optionsYears[0]}
            styles={customStyles}
          />
        </div>
        <div className="form-field-mobile">
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.data_type.label"
              defaultMessage="What kind of data and documents you need to import?"
            />
          </p>
          <Controller
            as={Select}
            isSearchable={false}
            name="docs_type"
            options={docsType}
            control={control}
            defaultValue={docsType[0]}
            styles={customStyles}
          />
        </div>

        <div className="form-field-mobile">
          <p className={'form-field__title'}>
            <FormattedMessage
              id="form_migration.docs_format.label"
              defaultMessage="Which is the format of files you need to import?"
            />
          </p>
          <Controller
            as={Select}
            isSearchable={false}
            name="file_type"
            options={fileType}
            control={control}
            defaultValue={fileType[0]}
            styles={customStyles}
          />
        </div>
        <button type="submit" className={'btn btn-lg btn-magenta'}>
          <FormattedMessage
            id="form_migration.cta"
            defaultMessage="Get a quote"
          />
        </button>
      </div>
    </form>
  )
}

export default FormMigrationMobile
